import React, { useCallback, useState, useEffect, useRef } from 'react'
import emailjs from 'emailjs-com'
import { createPortal } from "react-dom"
import styles from './ModalTime.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'
import logo from '../../image/ModalTime/logo.png'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"


export const ModalTime = ({onClose, popupOpen}) => {

    const modalRoot = document.getElementById("modals");
    
    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_zu8jx7s', 'template_c8ftnaq', form.current, 'pfHkibamtelgScs8G')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        onClose()
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, onClose, popupOpen] )

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
                <div className={styles.box_logo}>
                    <img className={styles.logo} src={logo} alt='логотип'/>
                    <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={onClose}/>
                </div>
                <div className={styles.box}>
                    <p className={styles.title}>Рады сообщить, что на данный момент действует скидка на логистику и доставку при заказе крупногабаритного оборудования. Оставьте свои контакты, и мысвяжемся, чтобы подготовить для Вас индивидуальное предложение</p>
                        <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите имя
                                    <input
                                        className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                        required/>
                                </label>
                            </div>
                            <span className={styles.input_error}>{handleForm.errors.name}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите телефон      
                                    <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите город
                                    <input
                                        className={`${styles.input} ${handleForm.errors.city && styles.wrong}`}
                                        type='text' 
                                        name='city'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                        required/>
                                </label>
                            </div>
                            <span className={styles.input_error}>{handleForm.errors.city}</span>
                                    <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>Отправить</span></button>
                        </form>
                </div>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};